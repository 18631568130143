<template>
    <div class="meterRecord">
        <!--    tabbar-->
        <CSTabBar :tabbar="orderState" :checkedTab="checkedTab" @changeTabBar="changeTabBar" class="tabBar"/>
        <div class="filter-panel">
            <CSSelect class="el-date" style="margin-right: 0;">
                <el-date-picker
                        v-model="queryParams.startDate"
                        type="datetime"
                        class="el-date-picker"
                        :picker-options="{disabledDate: e => elDisabledDate(e, true)}"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm:ss"
                        placeholder="请选择日期"
                        prefix-icon="el-icon-time"
                        :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <span class="inline-gray"></span>
            <CSSelect class="el-date">
                <el-date-picker
                        v-model="queryParams.endDate"
                        type="datetime"
                        class="el-date-picker"
                        :picker-options="{disabledDate: e => elDisabledDate(e, false)}"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm:ss"
                        placeholder="请选择日期"
                        prefix-icon="el-icon-time"
                        :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <input
                    type="text"
                    placeholder="搜索仪表名称/编号/位置/租客/抄表人/房间"
                    class="cs-input"
                    style=" margin-bottom: 15px;width: 290px;height: 30px "
                    v-model="queryData.search"
                    v-if="checkedTab==2"
            />
            <input
                    type="text"
                    placeholder="搜索仪表名称/编号/位置/抄表人"
                    class="cs-input"
                    style="margin: 0 0 15px 10px  ; margin-bottom: 15px;width: 230px;height: 30px "
                    v-model="queryData.search"
                    v-if="checkedTab==1"
            />
            <CSSelect style="margin-left: 20px">
                <select style="width: 149px;padding:0 5px;" v-model="queryData.type">
                    <option value="" style="width: 123px;">全部仪表种类</option>
                    <option v-for="(name, type) in speciesList" :key="type"
                            :value="type+1">
                        {{ name }}
                    </option>
                </select>
            </CSSelect>
            <CSSelect style="margin-right: 30px;">
                <select style="width: 149px; padding:0 5px;" v-model="queryData.species">
                    <option value="" style="width: 123px;">全部仪表类型</option>
                    <option v-for="(name, type) in typeList" :key="type"
                            :value="type+1">
                        {{ name }}
                    </option>
                </select>
            </CSSelect>
            <button
                    type="button"
                    class="btn btn-primary"
                    @click="query()"
            >
                查询
            </button>
        </div>
        <div class="result-panel">
            <!--    房间仪表-->
            <div v-if="checkedTab==2">

                <div class="result-panel pad">
                    <CSTable :thead-top="filterHeight">
                        <template v-slot:thead>
                            <tr>
                                <th>抄表时间</th>
                                <th>仪表名称/编号</th>
                                <th>仪表位置</th>
                                <th>所在房间</th>
                                <th>租客</th>
                                <th >仪表用量当前通知人</th>
                                <th>仪表种类</th>
                                <th>仪表类型</th>
                                <th>上次表底</th>
                                <th>表底</th>
                                <th>表底留存</th>
                                <th>用量统计</th>
                                <th>抄表人</th>
                                <th>备注</th>
                            </tr>
                        </template>
                        <template v-slot:tbody>
                            <tr
                                    v-for="(item,index) in homeData"
                                    :key="index"
                            >
                                <td class="center" style="min-width: 130px;">
                                    {{ item.readingTime }}
                                </td>
                                <td class="center">
                                    {{ item.meterName }}
                                </td>
                                <td class="center">
                                    {{ item.specificLocation }}
                                </td>
                                <td class="center">
                                    {{ `${item.buildingName}${item.roomCode}` }}
                                </td>
                                <td class="center">
               <span
                       class="allow-click"
                       @click="lookPerson(index)"
                       v-if="item.lesseeName!=''"
               >
                  查看
                </span>
                                    <span
                                            v-else
                                            class="noPerson"
                                    >
                </span>
                                </td>
                                <td class="center" style="min-width: 138px;">
                                    {{ item.deputyName ? `${item.deputyName}${item.deputyPhone}` : '-' }}
                                </td>
                                <td class="center">
                                    {{ item.type == 1 ? '水表' : item.type == 2 ? '电表' : '燃气表' }}
                                </td>
                                <td class="center">
                                    {{ item.style == 1 ? '机械表' : item.style == 2 ? '电子表' : '智能表' }}
                                </td>
                                <td class="center">
                                    {{ item.lastBottom }}
                                </td>
                                <td class="center">
                                    {{ item.bottom }}
                                </td>
                                <td class="center">
                <span
                        class="allow-click"
                        @click="lookRemain(item.readingPicUrl)"
                >
                  查看
                </span>
                                </td>
                                <td class="center">
                                    {{ item.usageCount }}
                                </td>
                                <td class="center">
                                    {{ item.readingUser }}
                                </td>
                                <td class="center">
                <span
                        class="allow-click"
                        @click="lookContent(item.comment)"
                        v-if="item.comment!=''"
                >
                  查看
                </span>
                                    <span
                                            v-else
                                            class="noPerson"
                                    >
                 </span>
                                </td>
                            </tr>
                        </template>
                    </CSTable>
                    <!--        table-->

                </div>
            </div>
            <!--    公区仪表-->
            <div v-else>
                <div class="result-panel">
                    <!--        table-->
                    <div class="pad">
                        <CSTable :thead-top="filterHeight">
                            <template v-slot:thead>
                                <tr
                                >
                                    <th>抄表时间</th>
                                    <th>仪表名称/编号</th>
                                    <th>仪表位置</th>
                                    <th>仪表种类</th>
                                    <th>仪表类型</th>
                                    <th>上次表底</th>
                                    <th>最新表底</th>
                                    <th>表底留存</th>
                                    <th>用量统计</th>
                                    <th>抄表人</th>
                                    <th>备注</th>
                                </tr>
                            </template>
                            <template v-slot:tbody>
                                <tr
                                        v-for="(item,index) in publicData"
                                        :key="index"
                                >
                                    <td class="center" style="min-width: 130px;">
                                        {{ item.readingTime }}
                                    </td>
                                    <td class="center">
                                        {{ item.meterName }}
                                    </td>
                                    <td class="center">
                                        {{ item.specificLocation }}
                                    </td>
                                    <td class="center">
                                        {{ item.type == 1 ? '水表' : item.type == 2 ? '电表' : '燃气表' }}
                                    </td>
                                    <td class="center">
                                        {{ item.style == 1 ? '机械表' : item.style == 2 ? '电子表' : '智能表' }}
                                    </td>
                                    <td class="center">
                                        {{ item.lastBottom }}
                                    </td>
                                    <td class="center">
                                        {{ item.bottom }}
                                    </td>
                                    <td class="center">
                <span
                        class="allow-click"
                        @click="lookRemain(item.readingPicUrl)"
                >
                  查看
                </span>
                                    </td>
                                    <td class="center">
                                        {{ item.usageCount }}
                                    </td>
                                    <td class="center">
                                        {{ item.readingUser }}
                                    </td>
                                    <td class="center">
                <span
                        class="allow-click"
                        @click="lookContent(item.comment)"
                        v-if="item.comment!=''"
                >
                  查看
                </span>
                                        <span
                                                v-else
                                                class="noPerson"
                                        >
                 </span>
                                    </td>
                                </tr>
                            </template>
                        </CSTable>
                    </div>
                </div>
            </div>
            <Pagination
                    name="Pagination"
                    component="Pagination"
            ></Pagination>
        </div>
        <!-- 租客对话框 -->
        <CSDialog
                dialog-header-class="alert-bg"
                dialogWidth="620px"
                :dialogVisible="personVisible"
                dialogTitle="当前租客"
                @onClose="CloseVisible"
                dialogCancelBtnText="关闭"
                :dialogShowConfirmBtn="false"
        >
            <div
                    slot="dialog-content"
                    style="font-size: 15px; padding: 15px 30px; "
            >
                <div class="dialog-form lesseeName">
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label">租客</div>
                        <div>
                            {{ currentHomeMeterInformation.lesseeName }}
                        </div>
                    </div>
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label">合同时间</div>
                        <div>
                            {{ currentHomeMeterInformation.contractPeriod }}
                        </div>
                    </div>
                    <div class="dialog-form-item">

                        <div class="dialog-form-item-label">对接人</div>
                        <div>
                            {{ currentHomeMeterInformation.deputyName }}
                        </div>
                    </div>
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label">手机号</div>
                        <div>
                            {{ currentHomeMeterInformation.deputyPhone }}
                        </div>
                    </div>
                </div>
            </div>
        </CSDialog>
        <!-- 图片组件 -->
        <ViewImageModal name="viewImageModal" componentName="ViewImageModal">
        </ViewImageModal>
        <!-- 备注对话框 -->
        <CSDialog
                dialog-header-class="alert-bg"
                dialogWidth="540px"
                :dialogVisible="contentVisible"
                dialogTitle="备注"
                @onClose="CloseVisible"
                dialogCancelBtnText="关闭"
                :dialogShowConfirmBtn="false"
        >
            <div
                    slot="dialog-content"
                    style="font-size: 15px; padding: 15px 30px; "
            >
                <div class="content" v-if="this.checkedTab==2">
                    {{ comment }}
                </div>
                <div class="content" v-else>
                    {{ comment }}
                </div>
            </div>
        </CSDialog>
    </div>
</template>

<script>
    import {
        queryMeterReading,
    } from "@/requestUrl";
    import Pagination from "@/components/Pagination";
    import ViewImageModal from "@/components/ViewImageModal";
    import CSDialog from '@/components/common/CSDialog';
    import CSTabBar from "@/components/common/CSTabBar";
    import CSSelect from "@/components/common/CSSelect";
    import dayjs from "dayjs";
    import CSTable from "@/components/common/CSTable";

    export default {
        name: "meterRecord",
        components: {
            CSTabBar,
            CSSelect,
            CSDialog,
            ViewImageModal,
            Pagination,
            CSTable
        },
        data() {
            return {
                filterHeight: 0,
                isLoadings: true,
                isLoading: true,
                orderState: {
                    1: "公区仪表",
                    2: "房间仪表"
                },
                checkedTab: 1,  //根据orderState显示tab-content
                //查询仪表数据
                queryData: {
                    search: "",    //选填
                    type: "",      //种类
                    species: "",   //类型
                    pageNo: 1,              //为空查询全部
                    pageSize: 10,            //默认10
                },
                //仪表种类和类型数据
                speciesList: [
                    '水表',
                    '电表',
                    '燃气表'
                ],
                typeList: [
                    '机械表',
                    '电子表',
                    '智能表'
                ],
                // 日期参数
                queryParams: {
                    startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD') + ' 00:00:00',
                    endDate: dayjs().format('YYYY-MM-DD') + ' 23:59:59',
                },
                personVisible: false,  //租客对话框显隐
                remainVisible: false, //表底对话框显隐
                contentVisible: false, //备注对话框显隐
                homeData: [],//房间数据
                publicData: [],//公区数据
                comment: '',
                currentHomeMeterInformation: {},//当前房间仪表信息
                currentPublicMeterInformation: {},//当前公区仪表信息
            }
        },
        created() {
            window.addEventListener("keydown", this.queryDown);
        },
        beforeDestroy() {
            window.removeEventListener("keydown", this.queryDown);
        },
        deactivated() {
            window.removeEventListener("keydown", this.queryDown);
        },
        mounted() {
            this.query()
            this.$vc.on(this.$route.path, "pagination", "page_event", (currentPage) => {
                this.query(currentPage);
            })
            this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
            window.addEventListener("resize", () => {
                this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
            });
        },
        methods: {
            queryDown(e) {
                if (e.keyCode == 13) {
                    this.query();
                }
            },
            //改变tabBar
            changeTabBar(tab) {
                this.checkedTab = tab
                Object.keys(this.queryData).forEach(key => {
                    this.queryData[key] = ''
                })
                this.query()
            },
            // 查询抄表记录
            query(pageNo = 1, pageSize = 10) {
                if (this.checkedTab == 2) {
                    //查询的房间数据
                    let params = {
                        meterInfo: this.queryData.search || '',
                        category: 1,
                        style: this.queryData.species || '',
                        type: this.queryData.type || '',
                        stime: this.queryParams.startDate,
                        etime: this.queryParams.endDate,
                        pageNo,
                        pageSize,
                    }
                    this.$fly.post(queryMeterReading, params)
                        .then((res) => {
                            this.homeData = res.data.datas;
                            this.isLoadings = false;
                            if (pageNo === 1) {
                                this.$vc.emit(this.$route.path, "pagination", "init", {
                                    total: res.data.total,
                                    currentPage: pageNo,
                                    pageSize,
                                })
                            }
                        })

                } else {
                    let params = {
                        meterInfo: this.queryData.search,
                        category: 2,
                        style: this.queryData.species,
                        type: this.queryData.type,
                        stime: this.queryParams.startDate,
                        etime: this.queryParams.endDate,
                        pageNo,
                        pageSize,
                    }
                    this.$fly.post(queryMeterReading, params)
                        .then((res) => {
                            this.publicData = res.data.datas;
                            this.isLoading = false;
                            if (pageNo === 1) {
                                this.$vc.emit(this.$route.path, "pagination", "init", {
                                    total: res.data.total,
                                    currentPage: pageNo,
                                    pageSize
                                })
                            }
                        })
                }
            },
            // 日期函数
            elDisabledDate(date, isStartDate) {
                if (isStartDate) {
                    return dayjs(date).isBefore(dayjs(this.queryParams.endDate).subtract(30, 'day'));
                }
                return dayjs(date).isAfter(dayjs(this.queryParams.startDate).add(30, 'day'));
            },

            //查看租客
            lookPerson(id) {
                this.personVisible = true
                this.getCurrentHomeMeterInformation(id)
            },
            //获取当前房间的仪表信息
            getCurrentHomeMeterInformation(id) {
                this.homeData.forEach((item, index) => {
                    if (index == id) {
                        this.currentHomeMeterInformation = item
                    }
                })
            },
            //表底留存
            lookRemain(url) {
                if (url.slice(0, 5) !== 'https') {
                    url = `https://alioss.techops.cn/${url}`
                    this.$vc.emit(this.$route.path, "viewImageModal", "view", [url]);
                }
                this.$vc.emit(this.$route.path, "viewImageModal", "view", [url]);
            },
            //备注
            lookContent(comment) {
                this.contentVisible = true
                this.comment = comment
            },
            //关闭租客对话框
            CloseVisible() {
                this.personVisible = false
                this.contentVisible = false
            }
        }
    }
</script>

<style scoped lang="stylus">
    th, td {
        text-align center !important
    }

    .pad
        padding-right 30px

    .dialog-form
        padding 27px 30px
        font-size 24px
        color #000

        &-item
            &:not(:last-of-type)
                margin-bottom 23px

            &-label
                width 156px
                height 33px
                text-align right
                margin-right 40px
                display inline-block
                vertical-align middle
                line-height 33px

            & > div
                display inline-block
                vertical-align middle

                input
                    &::placeholder
                        color #999
                    width 140px
                    height 40px
                    border-radius 4px
                    padding 0 5px
                    border 1px solid #979797
                    vertical-align middle
                    padding-left 10px

    .content
        font-size 24px
        display flex
        justify-content center

    .noPerson
        display inline-block
        border-top 1px solid #000
        height 1.5px
        width 4px

    .noRecord
        width 100px
        margin 0 auto

    .el-date
        width 230px
        margin-right 10px

    .el-date-picker
        width 210px

    .lesseeName
        white-space nowrap;
        overflow hidden
        text-overflow ellipsis

    .cs-input
        margin 0 0 15px 10px
</style>
<style scoped>
    @media only screen and (max-width: 1800px) {
        .cs-input {
            width: 200px !important;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .cs-select select {
            width: 140px !important;
        }

        .el-date-picker {
            width: 200px !important;
        }

        .el-date {
            width: 220px !important;
        }
    }

    .loading {
        animation: identifier 2s linear 0s infinite;
        width: 1px;
        background-color: black;
        height: 15px;
        margin-left: 5px;
    }

    .loading2 {
        animation: identifier 2s linear 0.1s infinite;
    }

    .loading3 {
        animation: identifier 2s linear 0.2s infinite;
    }

    .loading4 {
        animation: identifier 2s linear 0.3s infinite;
    }

    .loading5 {
        animation: identifier 2s linear 0.4s infinite;
    }

    .wrapper-loading {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @keyframes identifier {
        0% {
            height: 15px;
        }
        25% {
            height: 0px;
        }
        50% {
            height: 15px;
        }
        75% {
            height: 0px;
        }
        100% {
            height: 15px;
        }
    }
</style>
